"use strict";
(function() {
    $.fn.extend({
        animateCss: function(animationName, callback) {
            var animationEnd = (function(el) {
                var animations = {
                    animation: 'animationend',
                    OAnimation: 'oAnimationEnd',
                    MozAnimation: 'mozAnimationEnd',
                    WebkitAnimation: 'webkitAnimationEnd',
                };

                for (var t in animations) {
                    if (el.style[t] !== undefined) {
                        return animations[t];
                    }
                }
            })(document.createElement('div'));

            this.addClass('animated ' + animationName).one(animationEnd, function() {
                $(this).removeClass('animated ' + animationName);

                if (typeof callback === 'function') callback();
            });

            return this;
        },
    });

    $("#phone-dog").on('click', function(e) {
        // prevent default anchor click behavior
        e.preventDefault();

        // Animate
        $('html, body').animate({
            scrollTop: $(this.hash).offset().top
        }, 500, function(){

            // when done, add hash to url
            // (default click behaviour)
            //window.location.hash = this.hash;
            $('#content').addClass('fixed-top');
        });
    });

    $('.minor-feature-btn').on('click', function(e) {
        // prevent default anchor click behavior
        e.preventDefault();

        var phoneSrc = this.id.replace('icon', 'phone');
        $('#dog-phone').fadeOut(200).attr('src', $('#' + phoneSrc).attr('src')).fadeIn(400);
    });

    $('.main-icon').on('click', function(e) {
        // prevent default anchor click behavior
        e.preventDefault();

        var icon = $(this);


        switch (this.id) {
            case 'icon-recipe':
                icon.addClass('main-icon-paint');
                icon.animateCss('rubberBand', function() {
                    icon.removeClass('main-icon-paint');
                });
                break;

            case 'icon-illustrations':
                icon.addClass('main-icon-paint');
                icon.animateCss('bounce', function() {
                    icon.removeClass('main-icon-paint');
                });
                break;

            case 'icon-guide':
                icon.addClass('main-icon-paint');
                icon.animateCss('swing', function() {
                    icon.removeClass('main-icon-paint');
                });

                break;

            case 'icon-template':
                icon.addClass('main-icon-paint');
                icon.animateCss('tada', function() {
                    icon.removeClass('main-icon-paint');
                });
                break;
        }
    });

    $("#jumbo-btn, #footer-home, #features-btn, #reviews-btn, #get-app-btn, #contact-btn").on('click', function(e) {
        e.preventDefault();

        // Animate scrollto
        $('html, body').animate({
            scrollTop: $(this.hash).offset().top
        }, 500, function(){
            // Anything else need to do?
        });

        // Select button
        $('.nav-item').removeClass('active');
        $(this).parent().addClass('active');
    });

    var waypoint = new Waypoint({
        element: document.getElementById('content'),
        handler: function(direction) {
            if (direction == 'up') {
                $('#content').removeClass('fixed-top');
            } else {
                if (!$('#content').hasClass('fixed-top')) {
                    $('#content').addClass('fixed-top');
                }
            }
        }
    });

    AOS.init({
        duration: 1200
    });

    $('#contact-form').submit(function (e) {
        e.preventDefault();

        var name = $('#contact-name').val();
        var email = $('#contact-email').val();
        var subject = $('#contact-subject').val();
        var message = $('#contact-message').val();
        var token = $('#contact-csrf').val();

        $.ajax({
            type: 'POST',
            url: '/contact',
            data: $('#contact-form').serialize(),
            success:function(data){
                if (data && typeof data === 'object' && data.success) {
                    $('#contact-form').fadeOut("fast", function (e) {
                        $('#msg-success').fadeIn();
                    });
                } else {
                    $('#msg-error').fadeIn();
                }
            },
            error:function(data){
                $('#msg-error').fadeIn();
            }
        });
    });
})();
